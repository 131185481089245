import React, { useState } from 'react';
import bgImg from '../../Assets/linebg.png';
import { BiMobileVibration } from 'react-icons/bi';

const Home = () => {
  const currentYear = new Date().getFullYear();
  const [isCopied, setIsCopied] = useState(false);
  const codeSnippet = 'GET A NEW ID';

  const copyCode = () => {
    navigator.clipboard
      .writeText(codeSnippet)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 2000);
      })
      .catch((err) => console.log('Something went wrong', err));
  };

  return (
    <div
      className="bg-cover bg-center h-screen flex flex-col justify-between bg-black bg-opacity-50"
      style={{
        backgroundImage: `url(${bgImg})`,
      }}
    >
      {/* Navigation Bar */}
      <nav className="flex justify-between items-center bg-gradient-to-l from-[#3c71a3] to-[#a1156c] py-2 px-4 sm:px-6 lg:px-8 text-white font-normal text-sm tracking-wider">
        <div className="flex items-center">
          <BiMobileVibration size={20} className="mr-2" />
          <span>+91 8829909900</span>
        </div>
      </nav>

      {/* Main Content */}
      <div className="flex flex-col items-center justify-center flex-grow">
        {/* Main Heading */}
        <h1 className="text-white text-4xl md:text-6xl font-bold text-center mb-10 tracking-wider" style={{ fontFamily: 'cursive' }}>
          BET - BUDDY Enterprises
        </h1>

        {/* Code Snippet with Copy Button */}
        <div className="border-4 border-dotted border-white rounded-lg px-10 py-6 mb-10">
          <div className="text-2xl tracking-wider md:text-4xl font-extrabold text-[#d4e637]">
            {codeSnippet}
          </div>
          {/* <div className="flex justify-between items-center">
            <code className="block text-2xl tracking-wider md:text-4xl font-extrabold text-[#d4e637]">
              {codeSnippet}
            </code>
            <button
              onClick={copyCode}
              className="bg-transparent border border-white hover:text-[#d4e637] hover:border-[#d4e637] text-white py-2 px-4 tracking-wide rounded-md text-sm md:text-base transition-colors duration-300"
            >
              {isCopied ? 'Copied!' : 'Copy Code'}
            </button>
          </div> */}
        </div>

        {/* Welcome Bonus Text Section */}
        <p className="text-gray-300 tracking-wide text-xl md:text-2xl text-center px-4">
          Welcome bonus 10%
        </p>
      </div>

      {/* Footer Section */}
      <footer className="flex justify-center items-center bg-gradient-to-r from-[#3c71a3] to-[#a1156c] py-2 px-4 sm:px-6 lg:px-8 text-white font-normal text-md tracking-wider">
        <div>
          {currentYear} © <span className="tracking-wider">Bet-Buddy.</span>
        </div>
      </footer>
    </div>
  );
};

export default Home;
