import React from 'react';
import Navbar from '../../Components/Layout/Navbar';
import Footer from '../../Components/Layout/Footer';
import { FaWhatsapp } from "react-icons/fa6";

const NonAuthLayout = (props) => {
  const MessageButton = () => {
    const openMessageModal = () => {
      const phoneNumber = '918829909900';
      const whatsappUrl = `https://wa.me/${phoneNumber}`;
      window.open(whatsappUrl, "_blank");
    };

    return (
      <button
        className="fixed right-4 bottom-4 p-2 border border-gray-700 shadow-md shadow-gray-900 bg-[#160516] rounded-full hover:bg-[#0a2729] hover:scale-105 ease-in-out duration-300 focus:outline-none transition-transform transform-gpu"
        onClick={openMessageModal}
      >
        <FaWhatsapp size={42} color='#10b416' />
      </button>
    );
  };

  return (
    <React.Fragment>
      {/* <Navbar /> */}
      <MessageButton />
      {props.children}
      {/* <Footer /> */}
    </React.Fragment>
  )
}

export default NonAuthLayout
